<!--
 * @Author: your name
 * @Date: 2021-09-09 10:05:04
 * @LastEditTime: 2021-09-13 14:25:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\main\projectMana\proUpload.vue
-->
<template>
  <div>
    <div class="btn">
      <el-upload
        v-if="!isDetail"
        :headers="{ Authorization: 'Bearer ' + token, AppCode: 'whole-process' }"
        :action="uploadUrl"
        :multiple="true"
        :show-file-list="false"
        :on-success="handleSuccess"
        :on-error="uploadError"
        :on-progress="uploadProgress"
      >
        <el-button class="upload-button"><i class="el-icon-plus"></i>上传附件</el-button>
      </el-upload>
      <div class="dance">档案类型</div>
      <el-tabs tab-position="left" style="height: 350px;" v-model="tableActiveName" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in editableTabs" :key="item.id" :label="item.name" :name="item.id">
          <enclosure-table :ref="item.id" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import enclosureTable from './enclosureTable.vue';

export default {
  components: {
    enclosureTable
  },
  data() {
    return {
      uploadUrl: 'api/roses-pub-service/sysFileInfo/uploadResponse',
      uploadTabelLoading: false,
      tableActiveName: '',
      editableTabs: [],
      tableFileList: [],
      fileIdList: [],
      fileRelationParamList: []
    };
  },
  props: {
    //档案类型
    editableTabsData: {
      type: Array,
      default() {
        return [];
      }
    },
    //是不是详情
    isDetail: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters(['token']),
    // 使用ES6写法
    totalPrice() {
      const { fileRelationParamList, tableActiveName } = this;
      // return fileRelationParamList[tableActiveName];
      return [];
    }
  },
  methods: {
    //上传成功
    handleSuccess(response) {
      this.$message.success('上传成功');
      console.log(response.data);
      this.editableTabs.forEach(item => {
        if (item.id === this.tableActiveName) {
          // debugger;
          item.fileIdList.push(response.data);
        }
      });
      console.log(this.editableTabs, '444444444444444444');
      console.log(this.tableActiveName);
      // debugger;
      console.log(this.$refs[this.tableActiveName]);
      this.$refs[this.tableActiveName][0].testfun(response);
    },
    //上传失败的提示
    uploadError(err) {
      this.uploadTabelLoading = false;
      this.$message.error(err.message);
    },
    //文件上传时的钩子
    uploadProgress() {
      this.uploadTabelLoading = true;
    },
    handleClick(a) {
      // console.log(this.tableActiveName);
    },
    pushData() {
      console.log(this.editableTabs);
      // this.$emit('get-file-data', this.editableTabs);
    }
  },
  watch: {
    editableTabsData: {
      // value 需要深度监听及默认先执行handler函数
      handler(val) {
        if (val) {
          this.tableActiveName = val[0]?.id.toString();
          this.editableTabs = val;
          this.editableTabs[0].id = val[0]?.id.toString();
          // this.fileRelationParamList = [];
          this.editableTabs.forEach(item => {
            item.fileIdList = [];
          });
          console.log(this.fileRelationParamList);
          console.log(this.editableTabs);
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<style lang="less" scoped>
.btn {
  /deep/ .el-tabs__item {
    font-weight: 700;
    font-size: 16px;
    width: 300px;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>

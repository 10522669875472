<template>
  <el-container class="basic-layout">
    <el-header style="height: 64px">
      <Header />
    </el-header>
    <div class="out-layout">
      <div class="main-content">
        <div class="top-menu">
          <div class="left-menu">
            <div
              v-if="haveAllPermission"
              class="menu-title"
              :class="{ 'is-active': activeIndex === 1 }"
              @click="changeProject(1)"
            >
              所有项目
            </div>
            <div class="menu-title" :class="{ 'is-active': activeIndex === 2 }" @click="changeProject(2)">
              我创建的项目
            </div>
          </div>
          <el-form class="pro-form" ref="form" :model="form" :inline="true" label-width="80px">
            <el-form-item label="项目状态">
              <el-select v-model="queryParam.stateId" placeholder="请选择">
                <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="项目类型">
              <el-select v-model="queryParam.typeId" placeholder="请选择">
                <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="searchDate"
                type="daterange"
                :default-value="Date.now() - 24 * 3600 * 1000"
                style="width: 240px;"
                range-separator="-"
                start-placeholder="计划完成时间"
                end-placeholder="结束时间"
                value-format="yyyy-MM-dd"
                @change="dateChange($event)"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input
                style="width:260px;"
                v-model.trim="queryParam.projectName"
                placeholder="项目编号/项目名称/建设单位/立项人"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">搜索</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-main v-loading="loading">
          <div class="btns">
            <el-button type="primary" icon="el-icon-plus" @click="openAdd">新建</el-button>
            <div class="right-btn">
              <el-button type="text" class="export-download mr-16" @click="downloadClick">导入模板下载</el-button>
              <importUpload @get-file="getFile" />
              <el-button
                class="ml-16"
                :loading="exportLoading"
                :disabled="!getSelectList().length"
                @click="exportProject"
                >导出</el-button
              >
              <el-button type="danger" :disabled="!getSelectList().length" @click="delProject">批量删除</el-button>
            </div>
          </div>
          <div class="pro-list" v-if="projectList.length">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-for="(item, index) in projectList" :key="item.id">
                <div class="pro-item">
                  <div class="item-title">
                    <div
                      @mouseenter="e => isShowTooltip(e, item)"
                      @mouseout="hideTip(item)"
                      v-if="!item.isShowTooltip"
                      class="pro-name mr-16"
                      @click="goToDetail(item)"
                    >
                      <span style="font-weight: bold;">{{ item.projectName }}</span>
                      <span>
                        （项目编号：
                        <span class="pro-num">{{ item.projectNo }}</span
                        >）
                      </span>
                    </div>
                    <el-tooltip v-if="item.isShowTooltip" effect="dark" :content="item.projectName+' ( 项目编号:'+item.projectNo+')'" placement="top">
                      <div class="pro-name mr-16" @click="goToDetail(item)">
                        <span style="font-weight: bold;">{{ item.projectName }}</span>
                        <span>
                          （项目编号：
                          <span class="pro-num">{{ item.projectNo }}</span
                          >）
                        </span>
                      </div>
                    </el-tooltip>
                    <div>
                      <el-button
                        class="project-part"
                        @click="getProjectPart(item)"
                        v-if="item.projectResultList && item.projectResultList.length"
                        >项目分段
                      </el-button>
                    </div>
                  </div>
                  <div class="item-content">
                    <div class="item-head">
                      <div class="item-head-div">
                        <div class="head-money">{{ item.engineeringCost }}</div>
                        <div class="head-explain">工程造价（万元）</div>
                      </div>
                      <div class="item-head-div">
                        <div class="head-date">{{ item.planStartTime }}</div>
                        <div class="head-explain">计划开始时间</div>
                      </div>
                      <div class="item-head-div" style="margin-right: 40px;">
                        <div class="head-date">{{ item.planEndTime }}</div>
                        <div class="head-explain">计划结束时间</div>
                      </div>
                    </div>
                    <div class="item-core">
                      <div class="item-group">
                        <span class="item-label">项目状态：</span>
                        <span class="item-value">{{ item.stateName }}</span>
                      </div>
                      <div class="item-group">
                        <span class="item-label">项目类型：</span>
                        <span class="item-value">{{ item.typeName }}</span>
                      </div>
                      <div class="item-group">
                        <span class="item-label">建设单位：</span>
                        <span class="item-value">{{ item.relatedPartyName }}</span>
                      </div>
                      <div class="item-group">
                        <span class="item-label">立项人：</span>
                        <span class="item-value">{{ item.createUserName }}</span>
                      </div>
                      <div class="item-group">
                        <span class="item-label">主要建设内容：</span>
                        <div class="item-remark">
                          {{ item.buildContent }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-check">
                    <el-checkbox :checked="projectList[index].checked" @change="selectProject(item)"></el-checkbox>
                    <div>
                      <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                        <i class="iconfont icon-other-bianji" @click="openEdit(item.id)"></i>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" content="删除" placement="top">
                        <i class="iconfont icon-other-shanchu" @click="deleteThis(item.id)"></i>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-else class="empty-box">
            <el-empty description="暂无项目"></el-empty>
          </div>
        </el-main>
        <div class="pagination" v-if="projectList.length">
          <el-pagination
            :total="total"
            :current-page="queryParam.pageNo"
            :page-size="queryParam.pageSize"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[6, 12, 18, 24]"
            layout="total, prev, pager, next, sizes"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="项目分段" :visible.sync="dialogVisible" width="372px">
      <div class="dialog-content">
        <div class="item" v-for="item in projectItemList" :key="item.id">
          <el-tooltip effect="dark" :content="item.projectName" placement="top">
            <span class="item-name">{{ item.projectName }}</span>
          </el-tooltip>
          <span class="go-to-detail ml-16" @click="goToDetail(item, true)">进入</span>
        </div>
      </div>
    </el-dialog>
    <!-- <add-form ref="addFormRef" :isEdit="isEdit" @refresh="getProjectList()"></add-form> -->
    <!-- 新增/编辑项目 -->
    <ProjectDialog
      ref="projectDialogRef"
      :type-list="typeList"
      :status-list="statusList"
      @on-success="resetForm"
      @on-delete="resetForm"
    />
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/utils/store';
import Header from '@/views/layout/Header';
import addForm from './addForm.vue';
import ProjectDialog from './projectDialog.vue';
import { getProList, deleteProject, importProject } from '@/api/main/projectMana/index';
import { getBaseList, postEdit } from '@/api/common/common';
import importUpload from '@/components/upload/ImportUpload';
import { exportMethods } from '@/utils/exportMethods';
import textRange from '@/utils/textRange';

export default {
  components: { Header, addForm, ProjectDialog, importUpload },
  data() {
    return {
      activeIndex: 1,
      haveAllPermission: true,
      dialogVisible: false,
      form: {
        status: '',
        type: '',
        name: '',
        date: ''
      },
      exportLoading: false,
      searchDate: '',
      checked: false,
      queryParam: {
        pageNo: 1,
        pageSize: 6,
        typeId: '',
        stateId: '',
        planStartTime: '',
        planEndTime: '',
        projectName: ''
      },
      statusList: [],
      typeList: [],
      loading: false,
      total: 0,
      projectList: [
        // { id: 1, projectName: '温江实验室', checked: false }
      ],
      projectItemList: [], // 项目分段列表
      isEdit: false,
      editableTabsData: []
    };
  },
  created() {
    const menuCode = store.getSession('permissions').map(item => item.code);
    const activeI = sessionStorage.getItem('activeIndex');
    if (!menuCode.includes('checkAllPro')) {
      this.haveAllPermission = false;
      this.activeIndex = 2;
    } else {
      if (activeI) {
        this.activeIndex = Number(activeI);
      }
    }
    this.changeProject(this.activeIndex);
    this.getStatusList();
    this.getTypeList();
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      this.$refs.projectDialogRef.handleClose();
      history.pushState(null, null, document.URL);
    },
    // title hover 上去显示 tooltip
    isShowTooltip(e, item) {
      const bool = textRange(e.target);
      item.isShowTooltip = bool;
      this.$forceUpdate();
    },
    hideTip(item) {
      item.isShowTooltip = false;
    },
    // 获取项目状态
    getStatusList() {
      getBaseList({ type: 2 }).then(res => {
        this.statusList = res.data;
      });
    },
    // 获取项目类型
    getTypeList() {
      getBaseList({ type: 1 }).then(res => {
        this.typeList = res.data;
      });
    },
    getEnclosure() {
      getBaseList({ type: 8 }).then(res => {
        console.log(res);
        if (res.code !== 200) {
          return this.$message.error('获取数据失败：' + res.message);
        }
        this.editableTabsData = res.data;
      });
    },
    // 获取项目列表
    getProjectList() {
      this.loading = true;
      getProList(this.queryParam)
        .then(res => {
          this.projectList = res.data.rows;
          this.projectList.forEach(item => {
            item.checked = false;
            // item.isShowTooltip = false;
          });
          this.total = res.data.totalRows;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 切换所有的/我创建的项目
    changeProject(index) {
      delete this.queryParam.createUser;
      if (index === 2) {
        this.queryParam.createUser = this.userInfo.id;
      }
      this.queryParam.pageNo = 1;
      this.getProjectList();
      this.activeIndex = index;
      sessionStorage.setItem('activeIndex', index);
    },
    // 日期选择
    dateChange(val) {
      this.queryParam.planStartTime = val[0];
      this.queryParam.planEndTime = val[1];
    },
    // 勾选项目
    selectProject(item) {
      this.$set(item, 'checked', !item.checked);
      this.$forceUpdate();
    },
    // 进入项目详情
    goToDetail(data, type) {
      sessionStorage.setItem('projectId', data.id);
      sessionStorage.setItem('projectName', data.projectName);
      // debugger;
      if (type) {
        sessionStorage.setItem('isProject', type);
      }
      this.$router.push({
        path: '/center'
      });
    },
    // 项目分段
    getProjectPart(data) {
      this.projectItemList = [];
      this.dialogVisible = true;
      this.projectItemList = data.projectResultList;
    },
    // 搜索
    onSubmit() {
      this.getProjectList();
    },
    // 重置
    resetForm() {
      this.queryParam = {
        pageNo: 1,
        pageSize: 6,
        typeId: '',
        stateId: '',
        planStartTime: '',
        planEndTime: '',
        projectName: ''
      };
      this.searchDate = '';
      if (this.activeIndex === 2) {
        this.queryParam.createUser = this.userInfo.id;
      }
      this.getProjectList();
    },
    handleSizeChange(size) {
      this.queryParam.pageSize = size;
      this.queryParam.pageNo = 1;
      this.getProjectList();
    },
    handleCurrentChange(page) {
      this.queryParam.pageNo = page;
      this.getProjectList();
    },
    openAdd() {
      this.isEdit = false;
      // this.$refs.addFormRef.init('', this.editableTabsData);
      this.$refs.projectDialogRef.open();
    },
    getSelectList() {
      return this.projectList.filter(item => item.checked);
    },
    // 批量删除
    delProject() {
      if (!this.getSelectList().length) {
        this.$message.warning('请勾选需要删除的项目');
        return;
      }
      const ids = this.getSelectList()
        .map(item => item.id)
        .join(',');
      this.deleteThis(ids);
    },
    deleteThis(ids) {
      this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteProject({ ids }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getProjectList();
          });
        })
        .catch(() => {});
    },
    // 导出
    exportProject() {
      if (!this.getSelectList().length) {
        this.$message.warning('请勾选需要导出的项目');
        return;
      }
      this.exportLoading = true;
      const ids = this.getSelectList()
        .map(item => item.id)
        .join(',');
      exportMethods('/project/exportExcelData', { ids }, '项目列表').finally(() => {
        this.exportLoading = false;
      });
    },
    getFile(file) {
      let data = new FormData();
      data.append('file', file);
      importProject(data).then(res => {
        if (res.data.fileInfo) {
          // 导入失败
          this.$message.error(res.data.message);
          const a = document.createElement('a');
          const e = document.createEvent('MouseEvents');
          e.initEvent('click', false, false);
          a.href = res.data.fileInfo.filePath;
          a.download = res.data.fileInfo.fileOriginName;
          a.dispatchEvent(e);
          return;
        }
        this.$message.success(res.data.message);
        this.resetForm();
      });
    },
    // 编辑
    openEdit(id) {
      postEdit({ id }).then(res => {
        if (!res.success) {
          this.$message.error(res.message);
          return;
        }
        this.isEdit = true;
        this.$refs.projectDialogRef.open(res.data);
        // this.$refs.addFormRef.init(res.data, this.editableTabsData);
      });
    },
    downloadClick() {
      //下载模板
      let link = document.createElement('a');
      link.setAttribute('download', '项目批量导入模板');
      link.href = './项目模板.xlsx';
      link.click();
    }
  }
};
</script>

<style lang="less" scoped>
.basic-layout {
  width: 100%;
  height: 100%;
  .el-header {
    padding: 0;
  }
  .out-layout {
    width: 100%;
    background: url('../../../assets/images/bg.png') no-repeat;
    .main-content {
      margin: 16px 16px 0;
      background-color: #fff;
    }
  }

  .top-menu {
    padding: 0 25px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left-menu {
      display: flex;
      justify-content: flex-start;

      .menu-title {
        height: 70px;
        line-height: 70px;
        cursor: pointer;
        width: 130px;
        text-align: center;
      }

      .menu-title.is-active {
        color: #117bf7;
        font-weight: bold;
        border-bottom: 2px solid #117bf7;
      }
    }
  }

  .pro-form {
    padding-top: 18px;
  }

  .el-main {
    background: #f8f8f8;
    min-height: calc(100vh - 220px);
  }

  .btns {
    display: flex;
    justify-content: space-between;

    .export-download {
      color: #117bf7;
      border-bottom: 1px solid #117bf7;
    }

    .right-btn {
      display: flex;
    }
  }

  .pro-list {
    padding: 24px 0;
    .pro-item {
      background: #fff;
      margin-bottom: 24px;
      border: 1px solid #e8e8e8;
      .item-title {
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 47px;
        padding: 0 16px 0 16px;
        .pro-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;

          &:hover {
            color: #117bf7;
          }
        }

        .pro-num {
          color: #117bf7;
        }
        .project-part {
          border: 1px solid #117bf7;
          color: #117bf7;
          &:hover {
            background-color: #fff;
          }
        }
      }

      // .item-content {
      //   height: 216px;
      //   box-sizing: border-box;
      //   overflow: hidden;
      //   display: flex;
      //   justify-content: flex-start;
      //   flex-wrap: wrap;
      //   padding: 14px 16px;

      //   .item-group {
      //     margin: 0 30px 5px 0;
      //     width: 240px;
      //     white-space: nowrap;
      //     overflow: hidden;
      //     text-overflow: ellipsis;

      //     .item-value {
      //       color: #979fa8;
      //     }

      //     &:last-child {
      //       width: 100%;
      //       margin-right: 0;
      //     }
      //   }
      // }
      .item-content {
        height: 216px;
        padding: 14px 16px;
        .item-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 63px;
          width: 100%;
          border-bottom: 1px dashed #ccc;
          .item-head-div {
            .head-money {
              font-size: 24px;
              font-weight: bold;
              color: #f56c6c;
            }
            .head-explain {
              font-size: 12px;
              font-weight: 400;
              color: #979fa8;
              margin-top: 5px;
            }
            .head-date {
              font-size: 18px;
              font-weight: 400;
              color: #303133;
            }
          }
        }
        .item-core {
          height: 180px;
          box-sizing: border-box;
          overflow: hidden;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding: 14px 0;
          .item-group {
            margin: 0 16px 0 0;
            width: 240px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;

            .item-value {
              color: #979fa8;
            }
            .item-remark {
              margin-top: 5px;
              color: #979fa8;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &:last-child {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }

      .item-check {
        padding: 6px 16px;
        border-top: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .iconfont {
          color: #117bf7;
          cursor: pointer;
        }

        .icon-other-shanchu {
          color: #f56c6c;
          margin-left: 2px;
        }
      }
    }
  }

  .pagination {
    height: 70px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

/deep/ .el-checkbox__inner {
  border-radius: 50%;
}

/deep/ .el-dialog {
  .dialog-content {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .item-name {
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .go-to-detail {
      width: 50px;
      color: #117bf7;
      cursor: pointer;
    }
  }
}
</style>

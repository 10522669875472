<!--
 * @Author: your name
 * @Date: 2021-08-23 12:13:40
 * @LastEditTime: 2021-09-13 17:53:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\main\projectMana\demo.vue
-->
<template>
  <div>
    <el-form class="pro-form" ref="form" size="small" :model="form" :rules="rules" label-width="150px">
      <unfold-and-stow name="项目信息">
        <el-row>
          <el-col :span="8">
            <el-form-item label="项目编号">
              <el-input disabled v-model="form.projectNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2">
            <el-form-item prop="projectName" label="项目名称">
              <el-input
                v-model="form.projectName"
                @change="projectNameFunc"
                maxlength="100"
                placeholder="请输入"
                :disabled="isDetail"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="planStartTime" label="计划开始时间">
              <el-date-picker
                v-model="form.planStartTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="开始日期"
                style="width: 100%;"
                :picker-options="startPicker"
                :disabled="isDetail"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2">
            <el-form-item prop="planEndTime" label="计划结束时间">
              <el-date-picker
                v-model="form.planEndTime"
                type="date"
                placeholder="结束日期"
                style="width: 100%;"
                value-format="yyyy-MM-dd"
                :picker-options="endPicker"
                :disabled="isDetail"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="projectDuration" label="项目工期（月）">
              <el-input
                v-model="form.projectDuration"
                maxlength="3"
                :disabled="isDetail"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2">
            <el-form-item prop="projectAddress" label="项目地址">
              <el-input
                v-model="form.projectAddress"
                maxlength="100"
                :disabled="isDetail"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="engineeringCost" label="工程造价（万元）">
              <el-input
                v-model="form.engineeringCost"
                @input="numberInput"
                :disabled="isDetail"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2">
            <el-form-item label="造价大写">
              <el-input v-model="form.costBig" disabled placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="profit" label="预期利润（万元）">
              <el-input v-model="form.profit" :disabled="isDetail" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2">
            <el-form-item prop="typeId" label="项目类型">
              <el-select
                v-model="form.typeId"
                :disabled="isDetail"
                placeholder="请选择"
                @change="changeTypeName"
                style="width: 100%;"
              >
                <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="stateId" label="项目状态">
              <el-select
                v-model="form.stateId"
                placeholder="请选择"
                :disabled="isDetail"
                @change="changeStateName"
                style="width: 100%;"
              >
                <el-option v-for="item in stateOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item prop="buildContent" label="主要建设内容">
              <el-input
                v-model="form.buildContent"
                type="textarea"
                maxlength="500"
                :rows="5"
                resize="none"
                show-word-limit
                placeholder="请输入"
                :disabled="isDetail"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="工程等级">
              <el-input
                v-model="form.engineeringGrade"
                :disabled="isDetail"
                maxlength="100"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="2">
            <el-form-item label="项目跟踪人">
              <el-input
                v-model="form.projectTracker"
                :disabled="isDetail"
                maxlength="100"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="备注">
              <el-input
                v-model="form.remark"
                type="textarea"
                maxlength="500"
                :rows="5"
                resize="none"
                show-word-limit
                placeholder="请输入"
                :disabled="isDetail"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </unfold-and-stow>
      <unfold-and-stow name="相关方">
        <div class="btn" v-if="!isDetail">
          <el-button
            icon="el-icon-plus"
            :disabled="isDetail"
            style="margin-bottom: 12px;"
            size="small"
            @click="addRelevant"
            v-if="!isDetail"
            >新增相关单位</el-button
          >
          <div class="centerView">
            <div class="divView">
              <el-row>
                <el-col :span="22">
                  <el-form-item prop="relatedPartyId" label="建设单位">
                    <el-select
                      v-model="form.relatedPartyId"
                      @change="$forceUpdate()"
                      placeholder="请选择"
                      style="width: 100%;"
                      :disabled="isDetail"
                    >
                      <el-option
                        v-for="item in buildOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        style="width: 290px;"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item prop="contactAddress" label="联系地址">
                    <el-input
                      v-model="form.contactAddress"
                      maxlength="100"
                      :disabled="isDetail"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item prop="contacts" label="联系人">
                    <el-input
                      v-model="form.contacts"
                      maxlength="100"
                      :disabled="isDetail"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item prop="telephone" label="联系电话">
                    <el-input v-model="form.telephone" :disabled="isDetail" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="备注">
                    <el-input
                      v-model="form.relevantRemark"
                      type="textarea"
                      maxlength="500"
                      :disabled="isDetail"
                      :rows="4"
                      resize="none"
                      show-word-limit
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div
              class="divView"
              v-for="(item, index) in form.projectRelatedResultList"
              :key="item.code"
              :style="{ display: !isEdit || (isEdit && index !== 0) ? '' : 'none' }"
            >
              <i class="el-icon-error" :style="{ display: !isDetail ? '' : 'none' }" @click="deleteClick(item)"></i>
              <el-row>
                <el-col :span="22">
                  <el-form-item
                    :prop="'projectRelatedResultList.' + index + '.relatedPartyId'"
                    label="相关单位"
                    :rules="rules.relatedPartyId"
                  >
                    <el-select
                      v-model="item.relatedPartyId"
                      :disabled="isDetail"
                      placeholder="请选择"
                      style="width: 100%;"
                    >
                      <el-option
                        v-for="item in buildOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        style="width: 290px;"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item
                    :prop="'projectRelatedResultList.' + index + '.contactAddress'"
                    :rules="rules.contactAddress"
                    label="联系地址"
                  >
                    <el-input
                      v-model="item.contactAddress"
                      :disabled="isDetail"
                      maxlength="100"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item
                    label="联系人"
                    :prop="'projectRelatedResultList.' + index + '.contacts'"
                    :rules="rules.contacts"
                  >
                    <el-input
                      v-model="item.contacts"
                      :disabled="isDetail"
                      maxlength="100"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item
                    label="联系电话"
                    :prop="'projectRelatedResultList.' + index + '.telephone'"
                    :rules="rules.telephone"
                  >
                    <el-input v-model="item.telephone" :disabled="isDetail" placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="备注">
                    <el-input
                      v-model="item.remark"
                      type="textarea"
                      maxlength="500"
                      :rows="4"
                      resize="none"
                      show-word-limit
                      :disabled="isDetail"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="establishmentDept" label="立项部门" label-width="150px">
                <el-input
                  v-model="form.establishmentDept"
                  :disabled="isDetail"
                  maxlength="100"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :offset="2">
              <el-form-item prop="establishmentTelephone" label="联系电话">
                <el-input v-model="form.establishmentTelephone" :disabled="isDetail" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item prop="name" label="备注" :span="18" label-width="150px">
                <el-input
                  v-model="form.establishmentRemark"
                  type="textarea"
                  maxlength="500"
                  :rows="5"
                  resize="none"
                  show-word-limit
                  :disabled="isDetail"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-else class="btn">
          <el-tabs v-model="ProActiveName" tab-position="left" style="height: 300px;">
            <el-tab-pane
              v-for="(item, index) in form.projectRelatedResultList"
              :key="item.id"
              :label="item.relatedPartyName"
              :name="item.id"
              style="height: 60px;"
            >
              <el-form
                class="pro-form"
                ref="projectForm"
                size="small"
                :model="projectForm"
                :rules="projectRules"
                label-width="150px"
              >
                <el-row>
                  <el-col :span="13">
                    <el-form-item label="联系地址">
                      <el-input disabled v-model="item.contactAddress"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="13">
                    <el-form-item label="联系人">
                      <el-input disabled v-model="item.contacts"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="13">
                    <el-form-item label="联系电话">
                      <el-input disabled v-model="item.telephone"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="13">
                    <el-form-item label="备注">
                      <el-input
                        v-model="item.remark"
                        type="textarea"
                        maxlength="500"
                        :rows="5"
                        resize="none"
                        show-word-limit
                        placeholder="请输入"
                        :disabled="isDetail"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
          </el-tabs>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="establishmentDept" label="立项部门" label-width="150px">
                <el-input
                  v-model="form.establishmentDept"
                  :disabled="isDetail"
                  maxlength="100"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :offset="2">
              <el-form-item prop="establishmentTelephone" label="联系电话">
                <el-input v-model="form.establishmentTelephone" :disabled="isDetail" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item prop="name" label="备注" :span="18" label-width="150px">
                <el-input
                  v-model="form.establishmentRemark"
                  type="textarea"
                  maxlength="500"
                  :rows="5"
                  resize="none"
                  show-word-limit
                  :disabled="isDetail"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </unfold-and-stow>
      <unfold-and-stow name="附件" style="marginTop:24px;">
        <div class="btn">
          <el-upload
            v-if="!isDetail"
            :headers="{ Authorization: 'Bearer ' + token, AppCode: 'whole-process' }"
            :action="uploadUrl"
            :multiple="true"
            :show-file-list="false"
            :on-success="handleSuccess"
            :on-error="uploadError"
            :on-progress="uploadProgress"
          >
            <el-button class="upload-button"><i class="el-icon-plus"></i>上传附件</el-button>
          </el-upload>
          <div class="dance">档案类型</div>
          <el-tabs tab-position="left" style="height: 350px;" v-model="tableActiveName" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) in editableTabs" :key="item.id" :label="item.name" :name="item.id">
              <enclosure-table ref="editableTabsRef" @push-data="pushData" :isDetail="isDetail" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </unfold-and-stow>
    </el-form>
  </div>
</template>
<script>
import unfoldAndStow from '@/views/main/basicPage/_components/unfoldAndStow';
import proUpload from './proUpload.vue';
import enclosureTable from './enclosureTable.vue';
import { mapGetters } from 'vuex';
import { numberFun } from '@/utils/capitalizeNumber.js';
export default {
  components: {
    unfoldAndStow,
    proUpload,
    enclosureTable
  },
  props: {
    //项目类型
    typeOptionsData: {
      type: Array,
      default() {
        return [];
      }
    },
    //项目状态
    stateOptionsData: {
      type: Array,
      default() {
        return [];
      }
    },
    //档案类型
    editableTabsData: {
      type: Array,
      default() {
        return [];
      }
    },
    //建设单位
    buildOptionsData: {
      type: Array,
      default() {
        return [];
      }
    },

    //form数据
    formData: {
      type: Object,
      default() {
        return {};
      }
    },
    //是不是编辑
    isEdit: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //是不是详情
    isDetail: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  data() {
    // 数字校验
    const checkDeptTel = (rule, value, callback) => {
      // 正数正则
      const numberReg = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,6})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
      if (!value) {
        callback();
      } else {
        if (!numberReg.test(value)) {
          callback('请输入数字，小数点后不超过6位！');
        } else if (value >= 100000000) {
          callback('金额最大不超过8位！');
        } else {
          callback();
        }
      }
    };
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      if (!/^[0123456789]\d{10}$/.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    var checkNum = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      if (/^0|[^\d]/g.test(value)) {
        callback(new Error('请输入数字'));
      } else {
        callback();
      }
    };

    return {
      form: {
        projectNo: '',
        projectName: '',
        planStartTime: '',
        planEndTime: '',
        projectDuration: '',
        projectAddress: '',
        engineeringCost: '',
        costBig: '',
        profit: '',
        typeId: '',
        stateId: '',
        buildContent: '',
        engineeringGrade: '',
        projectTracker: '',
        remark: '',
        relatedPartyId: '',
        contacts: '',
        telephone: '',
        contactAddress: '',
        establishmentDept: '',
        establishmentTelephone: '',
        establishmentRemark: '',
        relevantRemark: '',
        stateName: '',
        isIndexOne: true,
        projectRelatedResultList: [],
        // 用来后端传数据用
        projectRelatedParamList: [],
        fileRelationParamList: [],
        fileData: []
      },
      reCode: 1,
      enclosureData: {
        id: '',
        index: ''
      },
      uploadUrl: 'api/roses-pub-service/sysFileInfo/uploadResponse',
      uploadTabelLoading: false,
      tableActiveName: '',
      tableFileList: [],
      fileIdList: [],
      typeOptions: [],
      stateOptions: [],
      buildOptions: [],
      rules: {
        projectName: [{ required: true, message: '请输入', trigger: 'blur' }],
        projectDuration: [
          { required: true, message: '请输入' },
          { validator: checkNum, trigger: 'change' }
        ],
        projectAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
        contactAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
        establishmentDept: [{ required: true, message: '请输入', trigger: 'blur' }],
        contacts: [{ required: true, message: '请输入', trigger: 'blur' }],
        engineeringCost: [
          { required: true, message: '请输入' },
          { validator: checkDeptTel, trigger: 'change' }
        ],
        profit: [
          { required: true, message: '请输入' },
          { validator: checkDeptTel, trigger: 'change' }
        ],
        typeId: [{ required: true, message: '请选择' }, { trigger: 'blur' }],
        stateId: [{ required: true, message: '请选择' }, { trigger: 'blur' }],
        relatedPartyId: [{ required: true, message: '请选择' }, { trigger: 'blur' }],
        buildContent: [{ required: true, message: '请输入', trigger: 'blur' }],
        telephone: [{ validator: checkPhone, trigger: 'blur' }],
        establishmentTelephone: [{ validator: checkPhone, trigger: 'blur' }],
        planStartTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
        planEndTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }]
      },
      startPicker: {
        disabledDate: time => {
          // if 判断另一项为空时可以随便选择时间
          if (this.form.planEndTime) {
            return time.getTime() > new Date(this.form.planEndTime).getTime();
          } else {
            return false;
          }
        }
      },
      endPicker: {
        disabledDate: time => {
          if (this.form.planStartTime) {
            return time.getTime() < new Date(this.form.planStartTime).getTime() - 1 * 24 * 60 * 60 * 1000;
          } else {
            return false;
          }
        }
      },

      activeName: '0',
      ProActiveName: '',
      editableTabs: [],
      projectForm: {},
      projectRules: {}
    };
  },
  created() {},

  methods: {
    init() {},
    // getFileData(data) {
    //   this.form.fileData = data;
    // },
    // 在子页面写校验规则 父页面调用这个方法校验
    async justify() {
      // debugger;
      //校验成功则方法自动返回true，校验方法报错则说明校验失败，返回false
      try {
        return await this.$refs['form'].validate();
      } catch (error) {
        return false;
      }
    },

    changeTypeName(id) {
      this.form.typeName = this.typeOptions.find(item => item.id === id).name;
    },
    //被检查管理单位方法
    changeStateName(id) {
      this.form.stateName = this.stateOptions.find(item => item.id === id).name;
    },
    closeFunc() {
      this.form = this.$options.data().form;
      this.$refs.form.resetFields();
    },
    addRelevant() {
      let obj = {
        relatedPartyId: '',
        contactAddress: '',
        contacts: '',
        telephone: '',
        remark: '',
        code: this.reCode
      };
      this.reCode++;
      // debugger;
      console.log(this.form);

      console.log(this.form.projectRelatedResultList);
      this.form.projectRelatedResultList.push(obj);
      // this.$set(this.form.projectRelatedResultList, 1, this.form.projectRelatedResultList[1]);
    },
    deleteClick(data) {
      console.log(data);
      console.log(this.form.projectRelatedResultList);
      if (data.id) {
        this.form.projectRelatedResultList.forEach((item, index) => {
          if (data.id == item.id) {
            this.form.projectRelatedResultList.splice(index, 1);
          }
        });
      } else {
        this.form.projectRelatedResultList.forEach((item, index) => {
          if (data.code == item.code) {
            this.form.projectRelatedResultList.splice(index, 1);
          }
        });
      }
    },
    handleClick(tab, event) {
      this.enclosureData = {
        id: tab.name,
        index: tab.index
      };

      console.log(tab);
      console.log(event);
    },
    numberInput(val) {
      const numberReg = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,6})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/;
      if (!numberReg.test(val)) {
        this.form.costBig = '';
      } else if (val >= 100000000) {
        this.form.costBig = '';
      } else {
        // debugger;
        this.form.costBig = numberFun(val * 10000);
      }
    },
    projectNameFunc(val) {
      console.log(val);
      this.$emit('nameFunc', val);
    },
    // addEnclosure(e) {
    //   let ref = this.$refs.projectRef;
    //   console.log(e);
    //   console.log(this.$refs.projectRef);
    //   if (this.enclosureData.index == '') {
    //     ref[0].pushdata();
    //   } else {
    //     ref[this.enclosureData.index].pushdata();
    //   }
    //   // for (let index = 0; index < ref.length; index++) {

    //   // }
    // },
    //上传成功
    handleSuccess(response) {
      this.$message.success('上传成功');
      console.log(response.data);
      this.editableTabs.forEach((item, index) => {
        if (item.id === this.tableActiveName) {
          // debugger;
          // item.fileIdList.push(response.data);
          this.$refs.editableTabsRef[index].testfun(response, this.tableActiveName);
        }
      });

      // console.log(this.$refs[this.tableActiveName]);
      // this.$refs[this.tableActiveName][0].testfun(response);
    },
    //上传失败的提示
    uploadError(err) {
      this.uploadTabelLoading = false;
      this.$message.error(err.message);
    },
    //文件上传时的钩子
    uploadProgress() {
      this.uploadTabelLoading = true;
    },
    handleClick(a) {
      console.log(this.tableActiveName);
    },
    pushData(data) {
      this.form.fileRelationParamList.push(data);
    },
    deleteData() {
      console.log(this.form);
      this.form.projectRelatedResultList.forEach((item, index) => {
        if (item.contactAddress === '' && item.contacts === '' && item.relatedPartyId === '') {
          this.form.projectRelatedResultList.splice(index, 1);
        }
      });
    }
  },
  watch: {
    typeOptionsData: {
      // value 需要深度监听及默认先执行handler函数
      handler(val) {
        if (val) {
          this.typeOptions = val;
          console.log(this.typeOptions);
        }
      },
      immediate: true,
      deep: true
    },
    stateOptionsData: {
      // value 需要深度监听及默认先执行handler函数
      handler(val) {
        if (val) {
          this.stateOptions = val;
          console.log(this.stateOptions);
        }
      },
      immediate: true,
      deep: true
    },
    editableTabsData: {
      // value 需要深度监听及默认先执行handler函数
      handler(val) {
        if (val) {
          console.log(val);
          this.tableActiveName = val[0]?.id.toString();
          this.editableTabs = val;
          this.editableTabs[0].id = val[0]?.id.toString();
          console.log(this.editableTabs);
          this.editableTabs.forEach(item => {
            item.fileIdList = [];
          });
        }
      },
      immediate: true,
      deep: true
    },
    buildOptionsData: {
      // value 需要深度监听及默认先执行handler函数
      handler(val) {
        if (val) {
          this.buildOptions = val;
          console.log(this.buildOptions);
        }
      },
      immediate: true,
      deep: true
    },

    formData: {
      handler(val) {
        console.log(val);
        if (val.isDelete === 0) {
          console.log(val);
          console.log(this.editableTabs);
          // 深拷贝 不然会影响到之前的数据
          this.form = { ...val };
          this.form.projectName = val.projectCopyName;
          console.log(this.form.fileRelationResultList);
          this.$nextTick(() => {
            if (val.editableTabsData.length !== 0) {
              this.form.fileRelationResultList.forEach(item => {
                val.editableTabsData.forEach((item1, index) => {
                  if (item.classId === item1.id) {
                    this.$refs.editableTabsRef[index].testfun(item, item.classId, true);
                  }
                });
              });
            }
          });
          this.form.typeId = this.form.typeId.toString();
          this.form.stateId = this.form.stateId.toString();
          // this.form.remark = this.form.remark;
          let dataOne = val.projectRelatedResultList[0];
          console.log(dataOne);
          if (this.isDetail) {
            this.ProActiveName = dataOne.id;
          }
          this.$set(this.form, 'establishmentDept', dataOne.establishmentDept);
          this.$set(this.form, 'establishmentTelephone', dataOne.establishmentTelephone);
          this.$set(this.form, 'establishmentRemark', dataOne.establishmentRemark);
          this.$set(this.form, 'relatedPartyId', dataOne.relatedPartyId.toString());
          this.$set(this.form, 'contactAddress', dataOne.contactAddress);
          this.$set(this.form, 'contacts', dataOne.contacts);
          this.$set(this.form, 'telephone', dataOne.telephone);
          this.$set(this.form, 'relevantRemark', dataOne.remark);
        } else if (val.projectName && val.projectName !== '项目主体') {
          this.form.projectName = val.projectName;
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<style lang="less" scoped>
.btn {
  text-align: left;

  .centerView {
    width: 100%;
    background-color: #f3f6fe;
    // padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .divView {
      position: relative;
      padding: 50px 0 0 10px;
      margin: 40px 3%;
      background-color: #ffffff;
      width: 485px;
      height: 326px;
      z-index: 10px;
      i {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #f56c6c;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .dance {
    color: #303133;
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0 20px 55px;
  }
  /deep/ .el-tabs__item {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    min-width: 300px;
    max-width: 130px;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-next {
    font-size: 18px;
  }
  /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left > .el-tabs__nav-prev {
    font-size: 18px;
  }
  /deep/.el-tabs--left,
  .el-tabs--right {
    padding: 20px 0;
    margin-bottom: 20px;
    border: 1px solid #dcdfe7;
  }
}
</style>

<!--
 * @Author: your name
 * @Date: 2021-08-25 10:45:14
 * @LastEditTime: 2021-09-13 18:10:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\main\projectMana\enclosureTable.vue
-->
<template>
  <div>
    <el-table :data="equipmentList" size="medium" stripe>
      <el-table-column width="60" fixed type="index" label="序号" align="center" :resizable="false"></el-table-column>
      <el-table-column prop="fileOriginName" label="文件名称" align="center" :resizable="false"></el-table-column>
      <el-table-column prop="fileSuffix" label="档案类型" align="center" :resizable="false"></el-table-column>
      <el-table-column prop="createUserName" label="上传人" align="center" :resizable="false"></el-table-column>
      <el-table-column prop="createTime" label="上传时间" align="center" :resizable="false"></el-table-column>
      <el-table-column prop="orgName" label="操作" align="center" :resizable="false">
        <template slot-scope="{ row }">
          <div class="operation">
            <span @click="previewFile(row)">查看</span>
            <span @click="downLoadFile(row.fileId, row.fileOriginName)">下载</span>
            <span
              style="border-bottom: 1px solid #F56C6C;color: #F56C6C;"
              v-if="!isDetail"
              @click="deleteFunc(row.fileId)"
              >删除</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { previewFile, downLoadFile } from '@/mixins/uploadFileOperation';

export default {
  mixins: [previewFile, downLoadFile],
  data() {
    return {
      equipmentList: [],
      testone: ''
    };
  },
  props: {
    //是不是详情
    isDetail: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  methods: {
    // //这个方法为每一行添加索引
    // tabelRowClassName({ row, rowIndex }) {
    //   row.index = rowIndex;
    //   return 'rows';
    // }
    testfun(res, classId, type) {
      // type 是不是查看详情赋值
      // debugger;
      console.log(res);
      if (type) {
        this.equipmentList.push(res);
        console.log(this.equipmentList);
        // return res.data;
        let fileObj = {
          classId,
          fileId: res.fileId
        };
        this.$emit('push-data', fileObj);
      } else {
        this.equipmentList.push(res.data);
        console.log(this.equipmentList);
        // return res.data;
        let fileObj = {
          classId,
          fileId: res.data.fileId
        };
        this.$emit('push-data', fileObj);
      }
    },
    deleteFunc(fileId) {
      console.log(row);
      this.equipmentList.map((item, index) => {
        let data;
        if (fileId === item.fileId) {
          data = this.equipmentList.splice(index, 1);
        }
      });
      console.log(this.equipmentList);
    }
  }
};
</script>
<style lang="less" scoped>
.operation {
  span {
    font-size: 14px;
    border-bottom: 1px solid #117bf7;
    color: #117bf7;
    margin: 0 8px;
    cursor: pointer;
  }
}
</style>

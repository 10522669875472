<template>
  <div>
    <el-dialog
      fullscreen
      :visible.sync="dialogVisible"
      :title="isEdit?'编辑项目':'新建项目'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="handleClose"
    >
      <el-button
        id="addBtn"
        type="primary"
        icon="el-icon-plus"
        style="margin-bottom: 12px;"
        @click="handleAddProjParam"
      >新增分段</el-button>
      <el-tabs v-model="activeTab" @tab-remove="handleRemoveSection">
        <el-tab-pane
          v-for="item in projectList"
          :key="item.code"
          :name="item.code"
          :closable="item.code !== '0'"
        >
          <el-tooltip
            placement="top"
            slot="label"
            :content="item.code ==='0' ? '项目主体' : item.projectName"
            :enterable="false"
          >
            <div class="tab-pane-label">{{ item.code ==='0' ? '项目主体' : item.projectName }}</div>
          </el-tooltip>

          <div>
            <unfold-and-stow name="项目信息">
              <ProjectForm ref="projectFormRef" :project-param="item" v-bind="$attrs" />
            </unfold-and-stow>

            <unfold-and-stow name="相关方">
              <ProjectRelated
                ref="projectRelatedRef"
                :related-list="item.projectRelatedParamList"
                :build-options="buildOptions"
              />
            </unfold-and-stow>

            <unfold-and-stow name="附件">
              <ProjectFiles
                ref="projectFilesRef"
                :file-list="item.fileRelationParamList"
                :file-type-list="fileTypeList"
              />
            </unfold-and-stow>
          </div>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="handleSubmit">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 新增分段对话框 -->
    <el-dialog
      width="600px"
      title="新建分段"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="addDialogVisible"
    >
      <el-form label-width="120px">
        <el-form-item
          :label="item.code === '0'?'项目主体':'项目分段名称'"
          v-for="item in subSectionList"
          :key="item.code"
        >
          <div class="form-item">
            <el-input
              :maxlength="100"
              show-word-limit
              :disabled="item.code === '0'"
              placeholder="请输入项目名称"
              v-model.trim="item.code === '0' ? '不允许操作主体项目' : item.projectName"
            ></el-input>
            <i
              class="el-icon-error"
              v-if="item.code !== '0'"
              @click="handleDeleteSubSection(item.code)"
            ></i>
            <i class="el-icon-error transprant" v-else></i>
          </div>
        </el-form-item>
      </el-form>

      <div class="btns" slot="footer">
        <el-button @click="handleAddSubSection">添加分段</el-button>
        <div>
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleSaveSubSection">保 存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';

import UnfoldAndStow from '@/views/main/basicPage/_components/unfoldAndStow';
import ProjectForm from './projectForm.vue';
import ProjectRelated from './projectRelated.vue';
import ProjectFiles from './projectFiles.vue';

import { getBaseList } from '@/api/common/common';
import { getAllRelatedName, postAdd, postEdit, deleteProject } from '@/api/main/projectMana';

export default {
  components: { UnfoldAndStow, ProjectForm, ProjectRelated, ProjectFiles },
  data() {
    return {
      dialogVisible: false,
      activeTab: '0',
      addDialogVisible: false,
      submitLoading: false,
      subSectionList: [],
      buildOptions: [],
      fileTypeList: [],
      projectList: [
        {
          code: '0',
          projectNo: '',
          projectName: '',
          planStartTime: '',
          planEndTime: '',
          projectDuration: '',
          projectAddress: '',
          engineeringCost: '',
          costBig: '',
          profit: '',
          typeId: '',
          typeName: '',
          stateId: '',
          stateName: '',
          buildContent: '',
          engineeringGrade: '',
          projectTracker: '',
          remark: '',
          projectRelatedParamList: [
            {
              code: '0',
              relatedPartyId: '',
              contactAddress: '',
              contacts: '',
              telephone: '',
              remark: '',
              establishmentDept: '',
              establishmentTelephone: '',
              establishmentRemark: '',
              type: ''
            }
          ],
          fileRelationParamList: []
        }
      ]
    };
  },
  computed: {
    isEdit() {
      const { projectList } = this;
      // 有id为删除，无id为新增
      return !!projectList[0].id;
    }
  },
  methods: {
    // 滚动方法
    scrollTop() {
      document.getElementById('addBtn').scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start' // 上边框与视窗顶部平齐。默认值
      });
    },
    // 获取相关方列表
    getRelatedListData() {
      getAllRelatedName().then(res => {
        this.buildOptions = res.data;
      });
    },
    // 编辑时 获取档案类型
    async getFileTypeList(isEdit) {
      const res = await getBaseList({ type: 3 });
      this.fileTypeList = res.data;
      if (isEdit) {
        this.fileTypeList.unshift({ id: '0', name: '全部文件' });
      }
    },
    // 删除分段接口
    async deleteProject(ids) {
      const res = await deleteProject({ ids });
      return res;
    },
    open(data) {
      this.getRelatedListData();
      if (data) {
        /* 编辑 */
        this.onEditProject(data);
      } else {
        /* 新增 */
        this.onIncrementProject();
      }
    },
    // 新增时打开弹窗
    onIncrementProject() {
      this.getFileTypeList().then(() => {
        // 手动设置第一个文件类型激活
        this.$refs.projectFilesRef.forEach(item => {
          item.setActiveName(this.fileTypeList[0].id);
        });
      });
      this.dialogVisible = true;
    },
    // 编辑时打开弹窗
    onEditProject(data) {
      this.getFileTypeList(true).then(() => {
        // 手动设置第一个文件类型激活
        this.$refs.projectFilesRef.forEach(item => {
          item.setActiveName('0');
        });
      });
      this.dialogVisible = true;
      // 循环分段
      data.forEach(item => {
        // parentId 为空，为项目主体
        item.code = item.parentId ? item.id : '0';

        item.projectRelatedParamList = item.projectRelatedResultList;
        item.projectRelatedParamList.forEach(subItem => {
          // 有立项部门为第一个建设单位
          subItem.code = subItem.establishmentDept ? '0' : subItem.id;
        });
        delete item.projectRelatedResultList;
        // 处理文件
        item.fileRelationParamList = item.fileRelationResultList || [];
        delete item.fileRelationResultList;
      });
      this.projectList = data;
    },
    // 点击新增分段
    handleAddProjParam() {
      const { projectList } = this;
      this.addDialogVisible = true;
      this.subSectionList = [];
      projectList.forEach(item => {
        const obj = {
          code: item.code,
          projectName: item.projectName
        };
        this.subSectionList.push(obj);
      });
    },
    // 点击添加分段
    handleAddSubSection() {
      this.subSectionList.push({
        code: nanoid(),
        projectName: ''
      });
    },
    // 点击删除分段
    handleDeleteSubSection(code) {
      this.$confirm('是否确认删除此项目分段, 删除后内容无法恢复，是否继续?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.subSectionList.splice(
          this.subSectionList.findIndex(item => item.code === code),
          1
        );
      });
    },
    // 点击保存分段
    handleSaveSubSection() {
      this.subSectionList = this.subSectionList.filter(item => {
        // 返回 项目主体 或者 项目名称不为空的分段数据
        return item.code === '0' || item.projectName !== '';
      });

      const projCodeList = this.projectList.map(item => item.code);
      const subCodeList = this.subSectionList.map(item => item.code);
      // 需要更新的项
      this.updataSubSection();

      // 需要删除的项
      const deleteCodeList = this.findDiffList(projCodeList, subCodeList);
      this.deleteSubSection(deleteCodeList);

      // 需要增加的项
      const incrementCodeList = this.findDiffList(subCodeList, projCodeList);
      this.incrementSubSection(incrementCodeList);

      this.addDialogVisible = false;
      this.activeTab = '0';
    },
    // 数组对比，找出在第一个数组中存在，第二个数组中不存在的项
    findDiffList(first = [], second = []) {
      return first.filter(item => {
        return second.every(subItem => item !== subItem);
      });
    },
    // 更新分段
    updataSubSection() {
      this.subSectionList.forEach(item => {
        this.projectList.forEach(subItem => {
          if (subItem.code === item.code) {
            subItem.projectName = item.projectName;
          }
        });
      });
    },
    // 删除分段
    deleteSubSection(deleteCodeList) {
      let ids = [];
      deleteCodeList.forEach(item => {
        const target = this.projectList.find(subItem => subItem.id === item);
        if (target) {
          ids.push(target.id);
        }
        this.projectList.splice(
          this.projectList.findIndex(subItem => subItem.code === item),
          1
        );
      });
      if (ids.length) {
        this.deleteProject(ids.join(',')).then(() => {
          this.$message.success('删除分段成功！');
          this.$emit('on-delete');
        });
      }
    },
    // 增加分段
    incrementSubSection(incrementCodeList) {
      incrementCodeList.forEach(item => {
        this.subSectionList.forEach((subItem, index) => {
          if (subItem.code === item) {
            const obj = {
              code: subItem.code,
              projectNo: '',
              projectName: subItem.projectName,
              planStartTime: '',
              planEndTime: '',
              projectDuration: '',
              projectAddress: '',
              engineeringCost: '',
              costBig: '',
              profit: '',
              typeId: '',
              typeName: '',
              stateId: '',
              stateName: '',
              buildContent: '',
              engineeringGrade: '',
              projectTracker: '',
              remark: '',
              projectRelatedParamList: [
                {
                  code: '0',
                  relatedPartyId: '',
                  contactAddress: '',
                  contacts: '',
                  telephone: '',
                  remark: '',
                  establishmentDept: '',
                  establishmentTelephone: '',
                  establishmentRemark: '',
                  type: ''
                }
              ],
              fileRelationParamList: []
            };
            this.projectList.push(obj);
            this.$nextTick(() => {
              this.$refs.projectFilesRef[index].setActiveName(this.fileTypeList[0].id);
            });
          }
        });
      });
    },
    // 点击删除分段 tab
    handleRemoveSection(name) {
      this.$confirm('是否确认删除此项目分段, 删除后内容无法恢复，是否继续?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const list = [name];
        this.deleteSubSection(list);
        this.activeTab = '0';
      });
    },
    // 点击确定
    handleSubmit() {
      // 同时校验 基础信息表单 与 相关方 表单
      const infoValidateRes = this.getValidateResult('projectFormRef');
      const relatedValidRes = this.getValidateResult('projectRelatedRef');
      // 基础信息通过后，再处理相关方校验
      this.handleValiedateRes(infoValidateRes, '基础信息')
        .then(() => {
          return this.handleValiedateRes(relatedValidRes, '相关方信息');
        })
        .then(() => {
          /* 所有分段校验都通过 */
          // 有 id 为编辑
          if (this.isEdit) {
            this.postEditProject(this.projectList);
          } else {
            this.postAddProject(this.projectList);
          }
        })
        .catch(reson => {
          this.handleValidateError(reson);
        });
    },
    /**
     * 获取子组件检验结果公共方法
     * @param {String} name 需要循环检验子组件的 ref 名称
     * @returns {Array} 各分段子组件检验结果
     */
    getValidateResult(name) {
      let res = [];
      this.$refs[name].forEach(itemRef => {
        res.push(itemRef.handleValidate());
      });
      return res;
    },
    /**
     * 处理校验结果的公共方法
     * @param {Array} index 各分段检验结果的数组
     * @param {String} name 校验未通过的子表单内容
     * @returns {Promise}
     */
    handleValiedateRes(res, name) {
      return new Promise((resolve, reject) => {
        // 找到第一个校验失败的基础信息 index , 将 tab 定向到此
        const index = res.findIndex(item => item === false);
        index < 0 ? resolve() : reject({ index, name });
      });
    },
    // 处理校验失败公共方法
    handleValidateError(reson) {
      const { index, name } = reson;
      const h = this.$createElement;
      // 切换到第一个校验失败的分段 tab
      this.activeTab = this.projectList[index].code;
      this.$message({
        type: 'warning',
        message: h('p', null, [
          h('span', { style: 'color: #333' }, '请完善该项目主体或分段下的 '),
          h('i', { style: 'color: #f56c6c' }, name)
        ]),
        duration: 5000
      });
      this.scrollTop();
    },
    // 新增接口
    postAddProject(params) {
      this.submitLoading = true;
      postAdd({ projectParamList: params })
        .then(res => {
          this.$message.success('新增项目成功！');
          this.handleClose();
          this.$emit('on-success');
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 编辑接口
    postEditProject(params) {
      this.submitLoading = true;
      postEdit({ projectParamList: params })
        .then(res => {
          this.$message.success('编辑项目成功！');
          this.handleClose();
          this.$emit('on-success');
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 点击取消
    handleClose() {
      this.dialogVisible = false;
      this.projectList = this.$options.data().projectList;
      this.activeTab = '0';

      const { projectFormRef, projectRelatedRef } = this.$refs;
      projectFormRef.forEach(refItem => {
        refItem.resetValid();
      });

      projectRelatedRef.forEach(refItem => {
        refItem.resetValid();
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__footer {
  text-align: center;
}
/deep/.el-dialog__title {
  font-weight: 700;
}
/deep/ .el-tabs__item {
  position: relative;
  font-size: 16px;
  min-width: 200px;
  max-width: 200px;
}
/deep/ .el-icon-close {
  position: absolute;
  right: 0;
  top: 4px;
}
/deep/.el-tabs__nav .el-tabs__item:nth-child(1) span {
  display: none;
}

.tab-pane-label {
  text-align: center;
  max-width: 165px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btns {
  display: flex;
  justify-content: space-between;
}

.form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-icon-error {
    margin-left: 20px;
    font-size: 20px;
    color: #f56c6c;
    cursor: pointer;
  }

  .transprant {
    cursor: default;
    color: #fff;
  }
}
</style>

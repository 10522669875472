<!--
 * @Author: your name
 * @Date: 2021-08-20 10:18:18
 * @LastEditTime: 2021-09-14 21:02:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\main\projectMana\addForm.vue
-->
<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-dialog
      fullscreen
      :visible.sync="isShow"
      title="新建项目"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeDialog"
    >
      <div>
        <el-button type="primary" icon="el-icon-plus" id="topBtn" style="margin-bottom: 12px;" @click="addTab"
          >新增分段</el-button
        >
        <el-tabs v-model="activeName" @tab-remove="removeTab" style="text-align: center;">
          <el-tab-pane
            v-for="(item, index) in editableTabs"
            :key="item.id"
            :closable="item.closable"
            :label="item.projectCopyName"
            :name="item.id"
          >
            <el-tooltip effect="dark" offset="50" :content="item.projectName" placement="top-end" slot="label">
              <span style="width: 130px;">{{ item.projectName }}</span>
            </el-tooltip>
            <add-project-list
              ref="projectRef"
              @nameFunc="nameFunc"
              :typeOptionsData="typeOptionsData"
              :stateOptionsData="stateOptionsData"
              :editableTabsData="editableTabsData"
              :buildOptionsData="buildOptionsData"
              :isEdit="isEdit"
              :formData="item"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="isAdd"
      title="新建分段"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
      @close="closeAdd"
    >
      <el-form class="pro-form" ref="form" size="small" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="项目主体">
              <el-input disabled v-model="form.name" placeholder="不允许操作主体项目"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-for="(item, index) in subsectionData" :key="item.name">
            <div class="itemClass">
              <el-form-item label="项目分段名称">
                <el-input v-model="item.sub" placeholder="请输入"></el-input>
              </el-form-item>
              <i class="el-icon-error" @click="deleteClick(index)"></i>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="bts" slot="footer">
        <el-button size="small" @click="addSubsection">添加分段</el-button>
        <div>
          <el-button size="small" @click.stop="closeAdd">取消</el-button>
          <el-button type="primary" size="small" @click="preservation">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import { postAdd, getType, getAllRelatedName, postEdit } from '@/api/main/projectMana';
import addProjectList from './addProjectList.vue';
export default {
  components: {
    addProjectList
  },
  props: {
    // 是否点的编辑按钮
    isEdit: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      fullscreenLoading: false,
      isShow: false,
      isAdd: false,
      allFormObj: {},
      confirmLoading: false,
      activeName: '0',
      editableTabs: [
        {
          projectName: '项目主体',
          id: '0'
        }
      ],
      subIndex: '1',
      form: {
        name: ''
      },
      subsectionData: [],
      copySub: [],
      typeOptionsData: [],
      stateOptionsData: [],
      editableTabsData: [],
      buildOptionsData: []
    };
  },
  methods: {
    init(data, editableTabsData) {
      this.isShow = true;
      this.getType();
      this.getState();
      // this.getEnclosure();
      this.editableTabsData = editableTabsData;
      this.getAllRelatedName();
      this.$nextTick(() => {
        if (data) {
          this.editableTabs = [];
          this.editableTabs = data;
          console.log(this.editableTabs);
          // 因为 editableTabs 写死了第一项是项目主体 所以赋值之前先清空
          this.activeName = data[0].id.toString();
          this.editableTabs.forEach(item => {
            item.closable = true;
            item.editableTabsData = editableTabsData;
            // 为了后面的赋值
            item.fileRelationParamList = [];
          });
          this.editableTabs[0].projectName = '项目主体';
          this.editableTabs[0].closable = false;
          console.log(this.editableTabs);
        } else {
          this.activeName = '0';
        }
      });
    },
    getType() {
      getType({ type: 1 }).then(res => {
        if (res.code !== 200) {
          return this.$message.error('获取数据失败：' + res.message);
        }
        this.typeOptionsData = res.data;
      });
    },
    getState() {
      getType({ type: 2 }).then(res => {
        console.log(res);
        if (res.code !== 200) {
          return this.$message.error('获取数据失败：' + res.message);
        }
        this.stateOptionsData = res.data;
      });
    },
    getAllRelatedName() {
      getAllRelatedName().then(res => {
        if (res.code !== 200) {
          return this.$message.error('获取数据失败：' + res.message);
        }
        this.buildOptionsData = res.data;
      });
    },
    // getEnclosure() {
    //   getType({ type: 8 }).then(res => {
    //     console.log(res);
    //     if (res.code !== 200) {
    //       return this.$message.error('获取数据失败：' + res.message);
    //     }
    //     this.editableTabsData = res.data;
    //   });
    // },
    closeDialog() {
      this.copySub = this.$options.data().copySub;
      // this.buildOptionsData = [];
      this.editableTabs = this.$options.data().editableTabs;

      console.log(this.$refs.projectRef);
      for (let index = 0; index < this.$refs.projectRef.length; index++) {
        this.$refs.projectRef[index].closeFunc();
      }
      this.isShow = false;
      this.allFormObj = {};
    },

    closeAdd() {
      this.isAdd = false;
    },
    addTab(targetName) {
      console.log(this.editableTabs);
      let arr = cloneDeep(this.editableTabs);
      arr.splice(0, 1);
      let arrData = [];
      // this.copySub = this.$options.data().copySub;

      // this.subsectionData = [];
      console.log(arr);
      arr.forEach(item => {
        let obj = {
          name: item.id,
          sub: item.projectName
        };
        arrData.push(obj);
      });
      this.subsectionData = arrData;
      this.isAdd = true;
    },
    nameFunc(val) {
      console.log(val);
      let data = [...this.editableTabs];
      console.log(this.$refs.projectRef);
      this.$refs.projectRef.forEach((item, index) => {
        if (val == item.form.projectName) {
          console.log(index);
          console.log(this.editableTabs);
          if (index !== 0) {
            data[index].projectName = val;
            this.editableTabs = data;
            console.log(this.editableTabs);
          }
        }
      });
    },
    addSubsection() {
      this.subIndex++;
      this.subsectionData.push({
        sub: '',
        name: this.subIndex.toString()
      });
    },
    deleteClick(index) {
      console.log(index);
      console.log(this.subsectionData);
      this.subsectionData.splice(index, 1);
    },
    preservation() {
      // 需求： list修改数据，增删改都会影响另外一个 tabs 数据
      // this.subsectionData 是list数据
      // this.editableTabs 是 tabs 数据
      let arr = cloneDeep(this.subsectionData);
      console.log(this.subsectionData);
      let flag = true;
      flag = arr.map(item => item.sub).some(item => item === '');
      if (!flag) {
        let editableTabsCopy = cloneDeep(this.editableTabs);
        let pushIdpList = this.deWeightTwo(arr, editableTabsCopy);
        // return;
        arr.forEach(item => {
          // 增
          pushIdpList.forEach(subItem => {
            if (item.name === subItem) {
              let obj = {
                projectName: item.sub,
                id: item.name,
                closable: true
              };
              editableTabsCopy.push(obj);
            }
          });
          // 因为上面只拿得到新增的 然后过滤掉了之前的,所以之前修改的值也不会保存
          // 然后现有的tabs数据 跟之前的对比 想通的id 就覆盖之前的名字 修改tabs名称
          // 改
          editableTabsCopy.forEach(editItem => {
            if (item.name == editItem.id) {
              editItem.projectName = item.sub;
            }
          });
          // 删
        });

        let deleteIdList = this.findDeleteIdList(arr, editableTabsCopy);
        console.log(deleteIdList);

        for (let i = 0; i < deleteIdList.length; i++) {
          const item = deleteIdList[i];
          for (let j = 0; j < editableTabsCopy.length; j++) {
            const element = editableTabsCopy[j];
            // 查看详情如果不写 element.parentId === '' 会把项目主体删掉
            if (element.id === '0' || element.parentId === '') {
              continue;
            }
            if (item === element.id) {
              editableTabsCopy.splice(j, 1);
              continue;
            }
          }
        }
        console.log(editableTabsCopy);
        this.editableTabs = editableTabsCopy;
      } else {
        this.$message.warning('请完善项目分段名称内容');
      }
      console.log(this.activeName);
      console.log(this.editableTabs);
      this.activeName = this.editableTabs[0].id;
      return (this.isAdd = flag);
    },
    //  删
    findDeleteIdList(arr, editableTabs) {
      // 同下
      let newArr = editableTabs
        .map(item => item.id)
        .filter(editItem => {
          return arr.map(item => item.name).every(arrItem => arrItem !== editItem);
        });
      return newArr;
    },

    // 找出需要新增的项
    deWeightTwo(arr, editableTabs) {
      // 先循环 list 中的 id 然后再 循环 tabs 的数据，拿到 id，如果 list 里的 id 在 tabs 里没有，
      // 就是 subitem !== item 然后就把当前 list 里的这个值拿出来 说明是新增
      return arr
        .map(item => item.name)
        .filter(item => {
          return editableTabs.map(item => item.id).every(subitem => subitem !== item);
        });
    },
    async handleConfirm() {
      console.log(this.$refs.projectRef);
      let arr = [];
      let flag = true;
      for (let index = 0; index < this.$refs.projectRef.length; index++) {
        this.$refs.projectRef[index].deleteData();
      }
      for (let index = 0; index < this.$refs.projectRef.length; index++) {
        const result = await this.$refs.projectRef[index].justify();
        console.log(this.$refs.projectRef[index]);
        this.$refs.projectRef[index].pushData();

        console.log(result);
        if (!result) {
          document.getElementById('topBtn').scrollIntoView({
            behavior: 'smooth', // 平滑过渡
            block: 'start' // 上边框与视窗顶部平齐。默认值
          });
          this.$message.warning('子组件校验失败');
          flag = false;
          break;
        } else {
          flag = true;
          console.log(this.$refs.projectRef);
          // this.$refs.projectRef[index].pushData();
          console.log(this.$refs.projectRef[index]);
          arr = this.$refs.projectRef.map(item => {
            console.log(item);
            let obj = [
              {
                relatedPartyId: item.form.relatedPartyId,
                contactAddress: item.form.contactAddress,
                contacts: item.form.contacts,
                telephone: item.form.telephone,
                remark: item.form.relevantRemark,
                establishmentDept: item.form.establishmentDept,
                establishmentTelephone: item.form.establishmentTelephone,
                establishmentRemark: item.form.establishmentRemark
              }
            ];
            // debugger;
            console.log(item.form.projectRelatedResultList);
            console.log(item.form.projectRelatedParamList);
            if (item.form.projectRelatedResultList !== undefined) {
              item.form.projectRelatedParamList = obj.concat(item.form.projectRelatedResultList);
              // 只有点击编辑时 才会把第一项赋值给第二项
              if (
                this.isEdit &&
                item.form.projectRelatedResultList !== undefined &&
                item.form.projectRelatedResultList[0]?.id
              ) {
                item.form.projectRelatedParamList[1].contactAddress =
                  item.form.projectRelatedParamList[0].contactAddress;
                item.form.projectRelatedParamList[1].contacts = item.form.projectRelatedParamList[0].contacts;
                item.form.projectRelatedParamList[1].establishmentDept =
                  item.form.projectRelatedParamList[0].establishmentDept;
                item.form.projectRelatedParamList[1].establishmentRemark =
                  item.form.projectRelatedParamList[0].establishmentRemark;
                item.form.projectRelatedParamList[1].establishmentTelephone =
                  item.form.projectRelatedParamList[0].establishmentTelephone;
                item.form.projectRelatedParamList[1].relatedPartyId =
                  item.form.projectRelatedParamList[0].relatedPartyId;
                item.form.projectRelatedParamList[1].remark = item.form.projectRelatedParamList[0].remark;
                item.form.projectRelatedParamList[1].telephone = item.form.projectRelatedParamList[0].telephone;
              }
            } else {
              item.form.projectRelatedParamList = obj;
            }

            console.log(item.form.fileRelationParamList);

            return item.form;
          });
          console.log('子组件校验成功');
        }
      }
      if (flag) {
        this.fullscreenLoading = true;
        if (!this.isEdit) {
          postAdd({ projectParamList: arr })
            .then(res => {
              if (!res.success) {
                return this.$message.error('数据更新失败，' + res.message);
              }
              this.$emit('refresh');
              this.$message.success('新增项目成功!');
              this.closeDialog();
            })
            .finally(() => {
              this.fullscreenLoading = false;
            });
        } else {
          arr.forEach((item, index) => {
            debugger;
            if (item.projectRelatedParamList[1]?.id !== undefined) {
              item.projectRelatedParamList.splice(0, 1);
            }
          });
          console.log(arr);
          postEdit({ projectParamList: arr })
            .then(res => {
              if (!res.success) {
                return this.$message.error('数据更新失败，' + res.message);
              }
              this.$message.success('编辑项目成功!');
              this.$emit('refresh');
              this.closeDialog();
            })
            .finally(() => {
              this.fullscreenLoading = false;
            });
        }
      }
    },
    unique(arr) {
      const res = new Map();
      return arr.filter(arr => !res.has(arr.fileId) && res.set(arr.fileId, 1));
    },
    removeTab(targetName) {
      // debugger;
      let tabs = this.editableTabs;
      let activeName = this.activeName;

      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          console.log(tab);
          console.log(index);
          console.log(targetName);
          if (tab.id === targetName) {
            console.log(index);
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.id;
            }
          }
        });
      }
      let indexCode = '';
      console.log(indexCode);
      this.subsectionData.forEach((item, index) => {
        if (item.id === targetName) {
          console.log(index);
          indexCode = index;
        }
      });
      this.activeName = activeName;
      this.editableTabs = tabs.filter(tab => tab.id !== targetName);
      console.log(this.subsectionData);
      this.subsectionData.splice(indexCode, 1);
      console.log(this.subsectionData);
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-dialog__title {
  font-weight: 700;
}
/deep/ .el-tabs__item {
  position: relative;
  font-weight: 700;
  font-size: 16px;
  min-width: 130px;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-icon-close {
  position: absolute;
  right: 5px;
  top: 4px;
}
/deep/.el-tabs__nav .el-tabs__item:nth-child(1) span {
  display: none;
}
/deep/.el-dialog__footer {
  text-align: center;
}
.bts {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  border-top: 2px solid #dcdfe7;
  padding-top: 12px;
}
.itemClass {
  position: relative;
  i {
    position: absolute;
    right: -30px;
    top: 6px;
    font-size: 20px;
    color: #f56c6c;
    cursor: pointer;
  }
}
</style>

// 判断文字是否超出宽度显示省略号
export default function textRange (el) {
  const textContent = el
  const targetW = textContent.getBoundingClientRect().width
  const range = document.createRange()
  range.setStart(textContent, 0)
  range.setEnd(textContent, textContent.childNodes.length)
  const rangeWidth = range.getBoundingClientRect().width
  return rangeWidth > targetW
}
